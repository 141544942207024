<script setup lang="ts">
const route = useRoute();
// Props
defineProps<{
  items: { label: string; to: string }[];
}>();
</script>

<template>
  <ui-card>
    <div class="flex gap-2 overflow-x-auto relative">
      <nuxt-link
        v-for="(tab, index) in items"
        :key="tab.to"
        :to="tab.to"
        class="group px-2 py-1 z-10 relative cursor-pointer whitespace-nowrap text-sm"
        :class="[
          route.path === tab.to
            ? 'font-medium text-[#1ba774] rounded-md bg-primary-100'
            : 'border-transparent text-gray-500 hover:text-[#1BA774] hover:border-gray-300 ',
        ]"
      >
        <slot :name="index" :tab="tab">
          {{ tab?.label }}
        </slot>
      </nuxt-link>
    </div>
  </ui-card>
</template>
